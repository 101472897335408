body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

html {
    overflow-x: hidden;
}


* {
    font-family: 'Roboto', sans-serif;
}

:root {
    --dark-color: hsl(262, 100%, 9%);
    --light-color: hsl(262, 95%, 98%);
    --base: hsl(262, 95%, 50%);
    --complimentary1: hsl(292, 95%, 50%);
    --complimentary2: hsl(322, 95%, 50%);


    /*--bg-gradient: linear-gradient(*/
    /*        to bottom,*/
    /*        hsl(262, 95%, 99%),*/
    /*        hsl(262, 95%, 84%)*/
    /*);*/

    --bg-gradient: linear-gradient(
            to bottom,
            hsl(262, 0%, 0%),
            hsl(262, 0%, 0%)
    );
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    color: var(--dark-color);
    /*background-color: rgba(0, 0, 0.3);*/
    /*background: var(--bg-gradient);*/
}

strong {
    font-weight: 600;
}

.overlay {
    width: 100%;
    max-width: 1140px;
    max-height: 640px;
    padding: 8rem 6rem;
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.375);
    box-shadow: 0 0.75rem 2rem 0 rgba(0, 0, 0, 0.1);
    border-radius: 2rem;
    border: 1px solid rgba(255, 255, 255, 0.125);
}

.overlay__inner {
    max-width: 36rem;
}

.overlay__title {
    font-size: 1.875rem;
    line-height: 2.75rem;
    font-weight: 700;
    letter-spacing: -0.025em;
    margin-bottom: 2rem;
}

.text-gradient {
    background-image: linear-gradient(
            45deg,
            var(--base) 25%,
            var(--complimentary2)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
}

.overlay__description {
    font-size: 1rem;
    line-height: 1.75rem;
    margin-bottom: 3rem;
}

.overlay__btns {
    width: 100%;
    max-width: 30rem;
    display: flex;
}

.overlay__btn {
    width: 50%;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.875rem;
    font-weight: 600;
    color: var(--light-color);
    background: var(--dark-color);
    border: none;
    border-radius: 0.5rem;
    transition: transform 150ms ease;
    outline-color: hsl(262, 95%, 50%);
}

.overlay__btn:hover {
    transform: scale(1.05);
    cursor: pointer;
}

.overlay__btn--transparent {
    background: transparent;
    color: var(--dark-color);
    border: 2px solid var(--dark-color);
    border-width: 2px;
    margin-right: 0.75rem;
}

.overlay__btn-emoji {
    margin-left: 0.375rem;
}

a {
    text-decoration: none;
}

.orb-canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: -1;
}

/* Not too many browser support this yet but it's good to add! */
@media (prefers-contrast: high) {
    .orb-canvas {
        display: none;
    }
}

@media only screen and (max-width: 1140px) {
    .overlay {
        padding: 8rem 4rem;
    }
}

@media only screen and (max-width: 840px) {
    /*body {*/
    /*    padding: 1.5rem;*/
    /*}*/
    .overlay {
        padding: 4rem;
        height: auto;
    }

    .overlay__title {
        font-size: 1.25rem;
        line-height: 2rem;
        margin-bottom: 1.5rem;
    }

    .overlay__description {
        font-size: 0.875rem;
        line-height: 1.5rem;
        margin-bottom: 2.5rem;
    }
}

@media only screen and (max-width: 600px) {
    .overlay {
        padding: 1.5rem;
    }

    .overlay__btns {
        flex-wrap: wrap;
    }

    .overlay__btn {
        width: 100%;
        font-size: 0.75rem;
        margin-right: 0;
    }

    .overlay__btn:first-child {
        margin-bottom: 1rem;
    }
}

.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 2rem;
    padding-left: 2rem;
}

@media (min-width: 1400px) {
    .container {
        max-width: 1400px;
    }
}

@media (max-width: 500px) {
    .container {
        padding: 0 5px;
    }

}
