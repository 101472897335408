
.accordion {
    border-top: 1px solid #ccc;
    margin-top: 1rem;
}

.itemBtn {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 1rem;
    font-size: 1rem;
    text-align: left;
    color: #fff;
    background-color: rgb(24 24 27/1);
    border: none;
}

.itemBtnExpanded {
    background-color: #e7e7e7;
}

.itemContent {
    transition: height 0.25s cubic-bezier(0, 0, 0, 1);
}

.itemPanel {
    padding: 1rem;
}

.chevron {
    margin-left: auto;
    transition: transform 0.25s cubic-bezier(0, 0, 0, 1);
}

.itemBtnExpanded .chevron {
    transform: rotate(180deg);
}
